<template>
  <div class="row bg-white">
    <div v-if="!isLoad" class="col-12">
      <div v-if="materials != null" class="row">
        <div class="col-12 mb-5">
            <div class="row">
                <div class="col-12">
                  <PreferencesDropdown
                  :themes="themes"
                  :current-theme.sync="currentTheme"
                  :font-size.sync="size"
                  v-model="searchQuery"
                  @searchResults="onSearchResults"
                  >
                  <template slot="book-content">
                      <button class="my-find bg-current my-content ml-5 mr-3 p-3" @click="showContent">
                          <i class="font-md fas fa-bars text-white"></i>
                      </button>
                      <div class="search-widget1 p-3" v-if="openContent">
                        <TreeMenu :subContent="toc"/>
                      </div>
                  </template>
                  <template slot="book-search">
                      
                  </template>
                  </PreferencesDropdown>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-8 col-md-10 col-sm-12">
                  <BookReader
                  :font-size="size"
                  :epub-url="materials.path ?  materials.path : url"
                  :themes="themes"
                  :theme="currentTheme"
                  :book-area="'area-content'"
                  :progress.sync="readingProgress"
                  @toc="getContent"
                  :contentBookModify="0"
                  >
                  <template slot="progress-bar" slot-scope="props">
                    <div class="w-100 text-center py-5 bg-current">
                      <input size="5" type="range" min="0" style="width: 95%" step="1"
                      @change="props.onChange($event.target.value)"
                      :value="readingProgress"
                    />
                    </div>
                  </template>
                    <template slot="prev-btn" slot-scope="props">
                        <div style="z-index: 10; left: 5%; font-size: 30pt;" class="arrow">
                          <a @click="props.goToPrevPage()"><i class="fas fa-caret-square-left"></i></a>
                        </div>
                    </template>
                    <template slot="next-btn" slot-scope="props">
                        <div style="z-index: 10; right: 5%; font-size: 30pt;" class="arrow">
                          <a @click="props.goToNextPage()"><i class="fas fa-caret-square-right"></i></a>
                        </div>
                    </template>
                  <!-- <template slot="book-content">
                      <div id="area-content" class="content-container mb-3"></div>
                  </template>
                  <template slot="progress-bar" slot-scope="props">
                      <div class="row justify-content-center mt-5">
                          <div class="col-12">
                              <div class="progress-bar bg-transparent w-100">
                                  <input size="3" type="range" min="1" step="1" id="input-progress-bar"
                                  @change="props.onChange($event.target.value)"
                                  :value="readingProgress"
                                  /> %
                                  <input class="d-none" type="text" id="input-progress-bar-value"
                                  @change="props.onChange($event.target.value)"
                                  :value="readingProgress"
                                  >
                              </div>
                          </div>
                      </div>
                  </template> -->
                  </BookReader>
                </div>
            </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 text-center py-3">
          <img :src="'/images/empty.png'" alt="" width="300">
          <h1 class="text-muted">Data don't exist</h1>
        </div>
      </div>
    </div>
    <div v-else class="col-12 text-center py-3">
      <div class="my-3">
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { BookReader, PreferencesDropdown, TreeMenu } from "vue-epub-reader";
export default {
  name: "App",
  components: {
    BookReader,
    PreferencesDropdown,
    TreeMenu
  },
  data() {
    return {
      isLoad: true,
      paramsId: this.$route.params.idCourse,
      idMaterial: this.$route.params.idFile,
      media: process.env.VUE_APP_URL_CLOUD,
      materials: null,
      url: "https://media-eling.intimultisolusi.com/storage/submission/9ORuMxb0bPbk5OnVDri8R0XkCZgn0OcgFLx56ucN.epub",
      size: 50,
      currentTheme: "white",
      themes: {
        white: {
          body: {
            color: "#000000",
            background: "#ffffff"
          },
          name: "WHITE"
        },
        beige: {
          body: {
            color: "#000000",
            background: "#f3e8d2"
          },
          name: "BEIGE"
        },
        night: {
          body: {
            color: "#ffffff",
            background: "#4a4a4a"
          },
          name: "NIGHT"
        }
      },
      searchQuery: "",
      readingProgress: 1,
      openSearch: false,
      openContent: false,
      searchContent: [],
      toc: []
    };
  },
  methods: {
    async getMaterials(){
      await axios.get(`${process.env.VUE_APP_URL_API}/teacher/material/detail?slug_course=${this.paramsId}&id=${this.idMaterial}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        }
      }).then(res => {
        this.isLoad = false
        this.materials = res.data
          
      }).catch((err) => console.error(err.response))
    },
    toggleSearchWidget() {
      this.openSearch = !this.openSearch;
    },

    showContent() {
      this.openContent = !this.openContent;
    },

    onSearchResults(value) {
      this.searchContent = value;
    },

    getContent(value) {
      this.toc = value;
    }
  },
  created(){
    this.getMaterials()
  },
  mounted() {
    this.$root.$on("toc", toc => {
      this.toc = toc;
    });
  }
};
</script>

<style scoped>
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}
.content-container{
    margin-top: 50vh;
    min-height: 100vh;
}
.search-widget1 {
  overflow: auto;
  padding: 20px;
  position: fixed;
  border: 5px solid #f1f1f1;
  background: #fff;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  height: 81%;
  z-index: 100;
}

.search-widget1 h3 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  color: #424949;
}

.search-widget1 ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 250px;
}

.search-widget1 li {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.search-widget1 li:before {
  font-family: FontAwesome;
  font-size: 20px;
  vertical-align: bottom;
  color: #dd3333;
  margin-right: 14px;
}

#area-content {
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin-top: 70px;
  z-index: 1;
}

#area-content iframe {
  border: none;
}

.progress-bar {
  margin-top: 100vh;
}

#input-progress-bar {
  width: 100%;
}

#input-progress-bar-value {
  width: 5%;
  text-align: right;
}

#find {
  width: 20px;
}

.buton {
    background-color: #4a4a4a;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    margin: 26px -90px;
    border-radius: 50%;
    font-family: 'Montserrat', sans-serif;
}
div >>> .hover {
  position: absolute !important;
  z-index: 1;
}

</style>
